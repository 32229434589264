<template>
  <UiPopup
    :model-value="modelValue"
    title="Release to pool"
    description="Please indicate the reason. Leads will be removed to pool."
    :primary-button-text="retrieving ? '' : 'Release to pool'"
    :secondary-button-text="retrieving ? '' : 'Cancel'"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="transition-all duration-200">
      <UiLoader v-if="retrieving" />
      <form v-else ref="form" @submit.prevent>
        <UiInputSelect
          v-model="data.pipeline_step_id"
          :items="reasonsItems"
          name="Reason"
          placeholder="Choose reason"
          class="mb-4"
          :error="v$.pipeline_step_id.$errors[0] ? v$.pipeline_step_id.$errors[0].$message.toString() : ''"
        />
        <UiInputSelect
          v-if="isLanguageBarrier"
          v-model="data.language_id"
          :items="languages"
          name="language"
          placeholder="Add language (optional)"
          class="mb-4"
        />
        <UiInputTextField v-model="data.reason" name="Other" placeholder="or add other reason" class="mb-4" />
      </form>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { PIPELINES, POOL_REASONS, POOL_STAGES } from '@/constants'
import type { Lead, Stage, LibraryItem, InputItem } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead?: Lead
  selectedLeads?: Lead[]
}

onNuxtReady(async () => await getPoolPipeline())

const retrieving = ref(true)
const poolVerifiedStage = ref()

const getPoolPipeline = async () => {
  retrieving.value = true
  const pool = await useGetPipelineByCode(PIPELINES.POOL)
  poolVerifiedStage.value = pool.stages?.find((s: Stage) => s.code === POOL_STAGES.VERIFIED)
  retrieving.value = false
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  lead: undefined,
  selectedLeads: undefined,
})

const loading = ref(false)
const data = ref({
  pipeline_step_id: null,
  language_id: null,
  reason: '',
})
const languages = ref<InputItem[]>([])

const reasonsItems = computed(() =>
  poolVerifiedStage.value.steps
    ?.filter((s: LibraryItem) => s.code !== POOL_REASONS.USER_DELETION)
    .map((s: LibraryItem) => ({ value: s.id, text: s.name, code: s.code }))
)

const isLanguageBarrier = computed(() => {
  return (
    data.value.pipeline_step_id &&
    reasonsItems.value?.find((s) => s.value === data.value.pipeline_step_id && s.code === POOL_REASONS.LANGUAGE_BARRIER)
  )
})

const rules = computed(() => ({
  pipeline_step_id: { required: helpers.withMessage('The reason is required', required) },
}))

const v$ = useVuelidate(rules, data)

onNuxtReady(async () => await getLanguages())

const getLanguages = async () => {
  const languagesList = await useLanguages()
  languages.value = languagesList.map((l) => ({ text: l.name, value: l.id }))
}

const findLanguageName = (id: number) => {
  return languages.value.find((l) => l.value === id)?.text
}

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
    loading.value = true
    const cleanData = omitBy(toRaw(data.value), (f) => f === '' || f === undefined)
    const payload = {
      ...cleanData,
    }
    delete payload.language_id

    let movedToRotation = false
    let leadIds: number[] = []
    if (props.selectedLeads?.length) {
      leadIds = props.selectedLeads.map((l) => l.id)
    }

    try {
      if (isLanguageBarrier.value && data.value.language_id) {
        if (props.selectedLeads?.length) {
          const userIds = {
            lead_ids: leadIds,
          }
          const { success } = await useBulkMoveLeadToRotation(data.value.language_id, userIds)
          if (success) {
            movedToRotation = true
          } else {
            payload.reason = findLanguageName(data.value.language_id)
          }
        } else {
          const { success } = await useMoveLeadToRotationByLanguage(props.lead!.id, data.value.language_id)
          if (success) {
            movedToRotation = true
          } else {
            payload.reason = findLanguageName(data.value.language_id)
          }
        }
      }

      if (!movedToRotation) {
        if (props.selectedLeads?.length) {
          payload.lead_ids = leadIds
          await useBulkMoveLeadToPool(payload)
        } else {
          await useMoveLeadToPool(props.lead!.id, payload)
        }
      }

      emits('input')
      emits('update:modelValue', false)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      loading.value = false
    }
  }
}
</script>

<style scoped></style>
